
<!-- 左侧导航栏 -->
<template>
  <div>
    <router-link v-for="item in routes" :to="item.path" :key="item.id" v-if="!item.hidden">

      <!--叶子级菜单-->
      <template v-if="item.menuType === 'CHILD'">
<!--      <template v-if="item.children && item.children.length === 0">-->
        <el-menu-item :key="item.id" :index="item.path" v-if="!item.hidden">
          <i :class="item.icon"></i>
          {{ item.viewName }}
        </el-menu-item>
      </template>

      <!--父级菜单-->
<!--      <el-submenu v-else :index="item.path" style="text-align: left">-->
      <el-submenu v-if="item.menuType === 'FATHER'" :index="item.path" style="text-align: left">

        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.viewName }}</span>
        </template>

        <SidebarItem  :routes="item.children"></SidebarItem>
      </el-submenu>

    </router-link>
<!--    <router-link v-for="item in routes" :to="item.path" :key="item.id" v-if="!item.hidden">-->
<!--      &lt;!&ndash;叶子级菜单&ndash;&gt;-->
<!--      <template v-if="item.children && item.children.length === 0">-->
<!--        <el-menu-item :key="item.id" :index="item.path" v-if="!item.hidden">-->
<!--          <i :class="item.icon"></i>-->
<!--          {{ item.viewName }}-->
<!--        </el-menu-item>-->
<!--      </template>-->

<!--      &lt;!&ndash;父级菜单&ndash;&gt;-->
<!--      <el-submenu v-else :index="item.path" style="text-align: left">-->

<!--        <template slot="title">-->
<!--          <i :class="item.icon"></i>-->
<!--          <span slot="title">{{ item.viewName }}</span>-->
<!--        </template>-->


<!--        <SidebarItem :routes="item.children"></SidebarItem>-->
<!--      </el-submenu>-->

<!--    </router-link>-->
  </div>
</template>
<script>
import bus from "../../utils/bus";

export default {
  name: 'SidebarItem',
  data() {
    return {
      collapse: global.collapse
    }
  },
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  created() {
    bus.$on('collapse', msg => {
      this.collapse = msg
    })
  }
}
</script>
<style scoped>
.title-display {
  display: none
}
</style>

