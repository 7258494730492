<template>
  <el-menu class="el-menu-vertical"
           :default-active="$store.state.maintabs.activePath"
           :unique-opened="uniqueOpened"
           :collapse="isMenuCollapse">

    <div class="menu-top">
      <img src="../../assets/logo.png" style="height: 60px;">
      <div class="sys-name">{{this.global.minProjectName}}</div>
    </div>

    <SidebarItem :routes="menuList"></SidebarItem>
  </el-menu>
</template>
<style lang="scss">
.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu.el-menu--collapse {
  .sys-name {
    display: none;
  }
}

.el-menu {
  .menu-top {
    width: 100%;
    height: 60px;
    background-color: #242f42;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;

    .sys-name {
      color: #FFFFFF;
      font-size: 18px;
    }
  }

  background-color: rgb(50, 65, 87); //rgb(50, 65, 87)  2c2c2c
  .el-submenu__title, .el-submenu__title > i {
    padding-left: 10px !important;
    color: #FFFFFF;
  }

  .el-menu-item {
    padding-left: 40px !important;
    color: #FFFFFF;
  }

  .el-menu-item.is-active,
  .el-menu-item.is-active:hover {
    background-color: #242f42; // #242f42  1F9FFF
    color: #FFFFFF
  }

  .el-submenu__title:hover,
  .el-menu-item:hover {
    border-left: 6px solid #FFFFFF;
    background-color: #242f42;
  }
}

[class^="el-icon-fa"], [class*="el-icon-fa"] {
  display: inline-block;
  font: normal normal normal 17px/1 FontAwesome !important;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$fa-css-prefix: el-icon-fa;
</style>

<script>
import SidebarItem from "./SidebarItem";

export default {
  name: "LayoutMenu",
  components: {SidebarItem},
  data() {
    return {
      uniqueOpened: true
    }
  },
  props: {
    isMenuCollapse: {
      type: Boolean
    }
  },
  computed: {

    menuList() {
      // 加载菜单
      // console.log("LayoutMenu.vue  22222222222222")
      return this.$store.state.maintabs.menuList;
    }
  }
}
</script>
