<template>
  <el-container>

    <el-aside style="width: auto;" class="scroll-view">
      <LayoutMenu :isMenuCollapse="isMenuCollapse"></LayoutMenu>
    </el-aside>


    <el-container>
      <el-header>
        <i :class="getMenuCollapse()" @click="handleCollapse"></i>

        <div class="header-right">
          <el-dropdown>
            <span class="el-dropdown-link">
                <el-avatar style="float: left"> {{ username }} </el-avatar>
                <i class="el-icon-arrow-down el-icon--right" style="padding-top: 20px"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="personal">个人中心</el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </el-header>
      <el-main class="scroll-view">
        <el-tabs v-model="$store.state.maintabs.activePath"
                 type="border-card" closable
                 @tab-click="clickTab"
                 @tab-remove="removeTab">
          <el-tab-pane v-for="item  in $store.state.maintabs.maintabs"
                       :key="item.path"
                       :label="item.viewName"
                       :name="item.path"
          ></el-tab-pane>
        </el-tabs>
        <div style="margin:50px 0 0 10px">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import LayoutMenu from './layout/LayoutMenu'
import {getUserName} from "@/lib/user";

export default {
  name: 'home',
  components: {
    LayoutMenu
  },
  data() {
    return {
      isMenuCollapse: false
    };
  },
  methods: {
    handleCollapse() {
      this.isMenuCollapse = !this.isMenuCollapse
    },
    getMenuCollapse() {
      return {
        "el-icon-s-fold": !this.isMenuCollapse,
        "el-icon-s-unfold": this.isMenuCollapse
      }
    },
    removeTab(path) {
      console.log("Home.vue removeTab " + path)
      if (path !== "/home/firstpage") {
        this.$store.commit('removeTab', path);
        const last_path = this.$store.state.maintabs.maintabs[this.$store.state.maintabs.maintabs.length - 1].path
        const query = this._get_tab_query(last_path)
        this.$router.push({path: last_path, query: query})
      }
    },
    clickTab(tab) {
      // console.log(tab.$options.propsData.name)
      const path = tab.$options.propsData.name
      const query = this._get_tab_query(path)
      this.$router.push({path: path, query:query})
    },
    logout() {
      this.$router.push({path: "/"});
      sessionStorage.clear()
      window.location.reload(); //刷一下可以清空vux-storel
    },
    personal() {
      this.$router.push({name: "Personal"});
    },

    /**
     * 依据path得到route参数
     * @param path
     * @private
     */
    _get_tab_query(path) {
      let list = this.$store.state.maintabs.maintabs
      for (let i = 0; i < list.length; i++) {
        if (list[i].path === path) {
          return list[i].query
        }
      }
      return null
    }


  },
  computed: {
    username() {
      console.log("Home.vue  显示用户名")
      return getUserName();
    }
  }
}
</script>

<style lang="scss">
#app > .el-container {
  padding: 0;
  margin: 0;
  height: 100%;
}

.header-right {
  float: right;
  display: flex;
  height: 100%;
  flex-direction: row;

  .right-item {
    height: 25px;
    color: #FFFFFF;
    margin-right: 20px;
    align-self: center;
  }

  .el-dropdown {
    flex: 1;
    text-align: right;
    align-self: center;

    .el-avatar {
      color: #1F9FFF;
      background-color: #ffffff;
    }
  }
}

.el-header {
  background-color: #242f42; // #242f42    //1F9FFF
  .el-icon-s-fold, .el-icon-s-unfold {
    font-size: 25px;
    color: #FFFFFF;
    line-height: 60px;
  }
}

.el-aside {
  background-color: rgb(50, 65, 87); // rgb(50, 65, 87)
}

.el-main {
  background-color: #FFFFFF;
  padding: 0 10px 10px 0;
  position: relative
}

.el-tabs {
  position: fixed;
  border: 0;
  width: 98%;
  z-index: 1000;
}

//当左侧菜单长度超出屏幕，可以滚动，
//但是不显示滚动条,这样更美观
.scroll-view::-webkit-scrollbar {
  display: none;
}

//不显示tab-content，内容区域我们使用router-view自己定义过
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}

.el-tabs--border-card > .el-tabs__header{
  // margin-left: 5px;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item{
  margin: 5px !important;
  border: 1px solid #909399ad ;
  
  border-radius: 4%;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #eff5ff !important;
  background-color: #409EFF;
  margin: 5px;
  border-color:#909399ad;

}


//第一个标签首页，不显示x关闭
.el-tabs__nav > .el-tabs__item:nth-child(1) span {
  display: none;
}

</style>
